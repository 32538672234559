import React from 'react'
import "./css/Sidebar.css"
import SidebarOptions from './SidebarOptions'

function Sidebar() {
  return (
    <div className='sidebar'>
        <SidebarOptions />
    </div>
  )
};

export default Sidebar
